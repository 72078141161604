import * as React from 'react';
import type { HeadFC } from 'gatsby';
import '../../global-styles/index.scss';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import MessageMe from '../../components/message-me';
import ContactBlock from '../../components/contact-block';
import { Helmet } from 'react-helmet';
import background from '../../assets/background-1.webp';
import RouterBlock from '../../components/router-block';

const ContactPage = () => {
	setTimeout(() => {
		document.querySelector('main')!.className = 'contact-page';
	}, 100);
	return (
		<main className="contact-page is-hidden">
			<Helmet
				htmlAttributes={{
					lang: 'lt'
				}}>
				<title>Kontaktai | Simona Grigaraitė</title>
				<meta name="description" content="Terapeutės Simonos Grigairatės asmeninė internetinė svetainė" />
				<meta name="image" content={background} />
				<meta name="og:description" content="Terapeutės Simonos Grigairatės asmeninė internetinė svetainė" />
				<meta name="og:image" content={background} />
			</Helmet>
			<Header></Header>
			<div className="main-wrapper is-vertical">
				<h1>Susisiekite</h1>
			</div>
			<ContactBlock></ContactBlock>
			<RouterBlock></RouterBlock>
			<Footer></Footer>
		</main>
	);
};

export default ContactPage;

export const Head: HeadFC = () => (
	<>
		<title>Kontaktai | Simona Grigaraitė</title>
		<meta name="description" content="Terapeutės Simonos Grigairatės asmeninė internetinė svetainė" />
		<meta name="image" content={background} />
		<meta name="og:description" content="Terapeutės Simonos Grigairatės asmeninė internetinė svetainė" />
		<meta name="og:image" content={background} />
	</>
);
