import * as React from 'react';
import InfoData from '../info-data';

const ContactBlock = (props: any) => {
	return (
		<div className="contact-block">
			<div className="main-wrapper">
				<ul className="contact-block__contact-list">
					<li className="contact-block__contact">
						<h3>Kabineto adresas</h3>
						<p>{InfoData.address[0]}</p>
						<p>{InfoData.address[1]}</p>
					</li>
					<li className="contact-block__contact">
						<h3>Kontaktai</h3>
						<a href={`mailto:${InfoData.email}`}>{InfoData.email}</a>
					</li>
				</ul>
				<iframe
					className="contact-block__map"
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2305.924889589487!2d25.266986216048256!3d54.693349380663406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46dd94064b9e9fcf%3A0x74a163ec1192b78b!2sA.%20Go%C5%A1tauto%20g.%208%2C%2001108%20Vilnius!5e0!3m2!1sen!2slt!4v1670252381310!5m2!1sen!2slt"
					width="600"
					height="450"
					loading="lazy"
					referrerPolicy="no-referrer-when-downgrade"></iframe>
			</div>
		</div>
	);
};

export default ContactBlock;
